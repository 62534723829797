<template lang="pug">
.master
	.container
		.main-container
			.title {{ title }}

			.display-image
				img(:src="img" :alt="title")

			.info-box
				<img class="veg" v-if="icon=='clock'" :src="require('@/assets/img/veg.png')">
				.subtitle {{ subtitle }}
				.description(v-if="description") {{ description }}
				.details(v-if="isIcon")
					img.icon(:src="computedIcon",alt="Time Square Logo")
					| {{detail}}
</template>

<script>
export default {
	props:{
		img:{type:String, required:true},
		title:{type:String, required:true},
		subtitle:{type:String, required:true},
		description:{type:String},
		icon:{type:String},
		detail:{type:String},
	},
	computed:{
		isIcon(){
			if(this.icon){
				return true
			}
			return false
		},
		computedIcon(){
			if(this.icon){
				return require('@/assets/img/' + this.icon + '-icon.svg')
			}else{
				return false
			}
		}
	}
};
</script>

<style scoped>
.master:nth-child(odd){
	background-color: #d7d5d0;
}
.details	{
	padding: 20px;
	margin: 10px;
	margin-bottom: 0px;
	vertical-align: middle;
}

.veg {
	width: 20px;
}


.info-box{
	margin: 2rem;
	margin-bottom: 0px;
}
.icon{
	vertical-align: -0.35rem;
	margin-right: 1rem;
	width: 1.5rem;
	height: 1.5rem;
	object-fit: contain;
	opacity: 0.6;
}

.main-container {
  position: relative;
  padding: 40px 0;
}

.title {
  font-family: "lato", serif;
  text-align: center;
  font-size: 4rem;
  font-weight: 200;
  margin-bottom: 2rem;
}

.subtitle {
  font-family: "DM Serif Display", serif;
  font-size: 1.5rem;
  font-weight: 100;
  margin-bottom: 1.5rem;
}

.description {
  font-size: 1.2rem;
}

.display-image {
  width: 45vw;
  margin: auto;
}

.display-image img {
  width: 100%;
  height: 100%;
  aspect-ratio: 1.7778;
  object-fit: cover;
  border-radius: 6px;
}

.info-box{
	padding: 2rem;
	text-align: center;
}


@media (max-width: 1024px) {
  .title {
	font-size: 3rem;
	font-weight: 200;
  }

  .subtitle {
	font-size: 1.2rem;
	font-weight: 100;
  }

  .description {
	font-size: 1rem;
  }

  	.display-image{
		width: 60vw;
	}
}

@media (max-width: 800px) {

	.display-image{
		width: 80vw;
	}

  .title {
	font-size: 2rem;
	font-weight: 200;
  }

  .subtitle {
	font-size: 1rem;
	font-weight: 100;
  }

  .description {
	font-size: 0.8rem;
  }
}
</style>
