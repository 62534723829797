<template lang="pug">
main
	a.whatsappWrapper(href="https://wa.me/+917579175791?text=I'm%20inquiring%20about%20the%20properties%20from%20Website!")
		img(src='../assets/img/WhatsApp.svg')
	display-header(:img="require('@/assets/img/real_site_images/resized/Disco.jpg')",headline="It's time to groove!")

	food-area-section(:img="require('@/assets/img/final_3d_images/low/img081.jpg')",title="Discotheque",subtitle="Food, Drinks, Music",description="Music that will force you to leave your seat and hit the dance floor.")

	food-area-section(:img="require('@/assets/img/final_3d_images/low/img078.jpg')", title="Cinema",subtitle="Dolby Atmos Cinema, First in the class.",description="Enjoy the adventure of movies.")

	food-area-section(:img="require('@/assets/img/final_3d_images/low/img076.jpg')",title="Bowling",subtitle="That's a strike!!!",description="Tie your Bowling Shoes, Experience the environment that gives wings to your bowling swag.")

	food-area-section(:img="require('@/assets/img/final_3d_images/low/img074.jpg')",title="VR Games",description="")

	location

</template>

<script>

// Imports
import DisplayHeader from '../components/DisplayHeader.vue'
import FoodAreaSection from '../components/XAreaSection.vue'
import Location from '../components/Location.vue'

// Vue Config
export default {
	 name: "",
  components: {DisplayHeader, FoodAreaSection, Location},
};
</script>

<style scoped>
.asd{
	display: block;
	padding-bottom: 5rem;
}

.featured-works-item{
	max-width: 800px;
	margin:auto;
	margin-bottom: 100px;


}
@media (max-width: 800px) {

.featured-works-item{
	height: auto;
}
}


</style>